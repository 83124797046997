.page {
  .bottomSide {
    
    :global {
      // .ant-table-wrapper {
      //   min-width: 500px;
      // }
      .ant-table-row-level-0 {
        background: #f1f3fc;
        color: #242a2d;
      }
      .ant-table-tbody > tr.ant-table-row.ant-table-row-level-0:hover > td {
        background: #f1f3fc;
      }
      .ant-table-row-indent + .ant-table-row-expand-icon {
        //color: rgba(0, 0, 0, 0.85);
        color: #cacfd4;
      }
      .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-content
        > table
        > thead
        > tr:first-child
        > th {
        &:nth-child(2) {
          // background: #f1f3fc;
          // color: #242A2D;
        }
      }
      .ant-table-container {
          font-size: 12px !important;
        }
      .ant-table-container .ant-table-thead >tr{
        &:nth-child(2){
          display: none;
        }
      }
      // .ant-table-container .ant-table-body > table > colgroup {
      //   width: 400px;
      // }
      .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-content
        > table
        > thead
        > tr:not(:last-child)
        > th {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    .headerWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        img {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }
    }
    .bgRow {
      background-color: #fafafa;
    }
  }
  :global {
    .ant-table-thead > tr:nth-child(2) > th:nth-child(2) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
