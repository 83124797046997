.tabContentItem{
  &_container{
    position: relative;
  }
  &_wrapper{
    display: flex;
    height: 100%;
    position: absolute;
  }
  &_menu{
    width: 162px;
    background-color: #fff;
    padding: 17px 13px 90px 20px;
    border-top: 1px solid #E7E7E7;
    height: 100%;
  }
  &_title{
    color: rgba($color: #000000, $alpha: 0.85);
    font-size: 14px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    &_link{
      color: #044DCC;
    }
  }
  &_contract{
    &Btn{
      background-color: #fff;
      margin-top: 200px;
      width: 15px;
      height: 74px;
      color: #657798;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px 0;
      box-shadow: 0px -4px 8px -4px rgba(0,0,0,0.12);
      cursor: pointer;
    }
  }
}