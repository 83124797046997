.newDate{
  position: absolute;
  top: 16px;
  z-index: 99;
  right: 206px;
}
.queryView{
  &__wrapper{
    overflow: hidden;
	.changeVersion{
		position: fixed;
		top: 80px;
		cursor: pointer;
	}
  }
  &__topMainContent{
    background-color: #fff;
    padding: 16px 20px 10px;
    display: flex;
  }
  &__bodyContent{
    display: flex;
    .bodyContent{
      &_wrapper{
        padding: 12px 16px 12px 0;
      }
      &_container{
        display: flex;
        align-items: center;
        & > span{
          font-weight: 500;
          font-size: 14px;
          color: rgba(0,0,0,0.85);
        }
      }
      &_overview{
        margin-left: 17px;
        background-color: #fff;
        box-shadow: 0px 5px 6px 0px rgba(107,156,250,0.32);
        border-radius: 6px;
        border: 1px solid rgba(31,129,255,0.66);
        display: flex;
        align-items: center;
        padding-right: 13px;
        overflow: hidden;
        & > div{
          background-color: #417DFF;
          border-radius: 0 0 6px 0;
          color: #fff;
          padding: 3px 10px;
          margin-right: 10px;
        }
      }
      &_varyContainer{
        display: flex;
      }
      &_newsContainer{
       width: 100%;
      }
      &_ratingContainer{
        flex: 2;
      }
    }
  }
}