.menuWrapper{
  .ant-anchor.ant-anchor-fixed{
    &::before{
      border: 0;
    }
    .ant-anchor-link{
      padding-inline: 0;
      padding: 7px 0px 7px 16px;
      &.ant-anchor-link-active{
        background: linear-gradient( 276deg, rgba(0,126,186,0) 0%, rgba(0,77,226,0.16) 100%);
        border-radius: 3px;
        border-left: 4px solid #044DCC;
      }
      .ant-anchor-link{
        padding-left: 16px;
      }
    }
    .ant-anchor-ink-visible{
      width: 0;
    }
  }
}