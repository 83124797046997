@import './variables.scss';

// 移动端默认设计稿为750px宽
$defaultSize: 75px;

@function pxToRem($size, $HTMLFontSize: $defaultSize) {
  $remSize: $size / $HTMLFontSize;
  @return #{$remSize}rem;
}

/**
 * @description: 盒子最大最小统一宽度
 */ 
@mixin containerBox ($widtth: 90%, $paddDis: 30px) {
  width: $widtth;
  padding: $paddDis;
  margin: 0 auto;
  border-radius: 4px;
  // max-width: $max-width;
}
/**
 * @description: 定位垂直水平居中
 */
 @mixin positionCenter ($x: 50%, $y: 50%) {
  position: absolute;
  left: $x;
  top: $y;
  transform: translate(-$x,-$y);
}
/**
 * @description: 定位垂直居中
 */
@mixin positionVerticalCenter ($y: 50%) {
  position: absolute;
  top: $y;
  transform: translateY(-$y);
}
/**
 * @description: flexBox垂直居中
 */
@mixin flexBoxVerticalCenter($jusCont: center, $aliItem: center) {
  display: flex;
  align-items: $aliItem;
  justify-content: $jusCont;
}
/**
 * @description: 操作按钮
 */
 @mixin oprationBtn( $bgColor: #fff, $fontColor: $Theme__button_font1, $borColor: $Theme__button_border1) {
  background-color: $bgColor;
  color: $fontColor;
  border: 1px solid $borColor;
  border-radius: 2px;
  text-align: center;
  padding: 4px 30px;
  margin-right: 10px;
  cursor: pointer;
  &:hover{
    background-color: $bgColor;
    color: $fontColor;
    border: 1px solid $borColor;
  }
}
