.footer-container {
  white-space: pre;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  background-color: #23292E;
  color: #fff;
  & > img {
    height: 30px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .footer{
    &-container {
      height: auto;
      padding: 27px 16px;
      justify-content: space-between;
    }
    &_logos{
      margin-bottom: 22px;
      & > img {
        height: 27px;
        margin-left: 20px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
    &_contact{
      font-size: 14px;
      span{
        display: block;
      }
      a, a:hover{
        color: #fff;
      }
    }
    &__loggedContent{
      text-align: center;
      width: 40%;
      position: static;
      transform: none;
      &_qrcode{
        width: 80px;
        padding: 4px;
        margin-bottom: 9px;
      }
      &_scanTips{
        font-size: 12px;
      }
    }
  }
}