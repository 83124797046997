.header {
  // padding: 0 44px 0 41px;
  padding: 0 23px 0 20px;
  height: 64px;
  display: flex;
  align-items: center;
  position: relative;
  & > img {
    width: 215px;
    height: 28px;
  }
  .header-right {
    margin-left: auto;
    .name {
      min-width: 60px;
      color: rgba(255,255,255,0.85);
    }
  }
}
@media screen and (max-width: 767px) {
  .header{
    padding: 0 16px 0 20px;
    & > img {
      width: 154px;
      height: 20px;
    }
    .header-right{
      padding-right: 37px;
      .technicalSupport, .technicalSupport:hover{
        background-color: transparent;
        border: 1px solid #126BF1;
        color: #126BF1;
      }
    }
    .menuDrawer{
      position: absolute;
      right: 16px;
      top: 0;
      color: #fff;
      font-size: 20px;
    }
  }
  .logoutBtn{
    position: absolute;
    bottom: 32px;
    width: calc(100% - 20px);
    padding: 6px 0;
    text-align: center;
    color: rgba(0,0,0,0.45);
    border: 1px solid rgba(0,0,0,0.45);
    border-radius: 8px;
    cursor: pointer;
  }
}
.openMobileTip{
  &__wrapper{
    padding: 0 16px 16px;
    text-align: center;
    font-size: 14px;
  }
  &__btnContainer{
    padding: 0 16px 16px;
    text-align: center;
  }
  &__option{
    background-color: #126BF1;
    color: #fff;
    border-radius: 8px;
    margin-top: 26px;
    padding: 6px 0;
  }
}
.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  background-image: url('../../assets/images/main_bg.png');
  background-position: center/center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.modalContainer{
  &__wrapper{
    background: linear-gradient( 180deg, #F1F5FF 0%, #FFFFFF 24%, #FFFFFF 100%);
    text-align: center;
    border-radius: 8px;
  }
  &__headerIcon{
    width: 215px;
    margin: 34px 0;
  }
  &__mainTitle{
    font-size: 16px;
    color: rgba(0,0,0,0.85);
  }
  &__subTitle{
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    margin: 12px 0 24px;
    span{
      color: rgba(0,0,0,0.45);
    }
  }
  &__form{
    padding: 0 60px 26px;
  }
}
.verifyCode{
  &__input{
    width: 100%;
    margin-bottom: 0;
  }
  &__btn{
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    z-index: 100;
    cursor: pointer;
    border-left: 1px solid rgba(0,0,0,0.15);
    &_txt{
      padding-left: 13px;
      color: #126BF1;
      &.verfied{
        color: #5B6990;
        cursor: not-allowed;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .modalContainer{
    &__form{
      padding: 0 14px;
      &.currentSecound{
        padding-bottom: 0px;
      }
    }
  }
  .verifyCode{
    &__input{
      width: 100%;
      margin-right: 0 !important;
    }
    &__btn{
      &_txt{
        font-size: 12px;
      }
    }
  }
}