.subjectCreditBarChart {
  min-height: 400px;
  // background: #f7f8fa;
  // padding: 24px;
  // margin-top: 20px;
  border-radius: 8px;
  position: relative;
  .head {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 16px;
    color: #1d2129;
    :global {
      .ant-radio-button-wrapper {
        background-color: transparent;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0,0,0,0.6);
        border: none;
        &::before {
          background-color: transparent;
        }
        &:hover {
          background-color: transparent;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0,0,0,0.6);
        }
      }
      .ant-radio-button-wrapper-checked {
        background-color: #FFFFFF;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: rgba(0,0,0,0.9);
        border:none;
        &::before {
          background-color: transparent;
        }
        &:hover {
          background-color: #FFFFFF;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: rgba(0,0,0,0.9);
        }
      }
    }
    .beizhu {
      font-weight: 400;
      font-size: 14px;
      color: #86909c;
      display: flex;
      align-items: center;
      img{
        display: inline-block;
        width: 18px;
        height: 18px;
      }
    }
  }
  .info {
    position: absolute;
    top: 40px;
    left: 12px;
    display: flex;
    align-items: center;
    .name {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #86909C;
      margin-right: 56px;
    }
    .symbol {
      display: flex;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #1D2129;
      .signBox {
        width: 10px;
        height: 10px;
        background: #165DFF;
        margin-right: 5px;
      }
    }
  }
}
