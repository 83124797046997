@import './mixin';
@import './variables';

// 查询条件
.searchConfirm{
  @include oprationBtn($Theme__button_bgColor,$Theme__button_font1)
}
.searchReset{
  @include oprationBtn(#fff,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.8))
}
.tableInfoContainer{
  @include containerBox(100%, 24px);
  background-color: #fff;
  margin-bottom: 20px;
  box-sizing: border-box;
  .table-component{
    .table-box.ant-table-wrapper{
      padding: 0;
    }
  }
}
.form{
  &__addNew{
    &Container{
      @include flexBoxVerticalCenter(flex-end);
      color: $Theme__font_color1;
      font-size: $Theme__font_size_default;
      background-color: #fff;
      &.extend_tab{
        margin-bottom: 0;
      }
    }
  }
}
.homeView__searchFormItem.ant-form-item{
  .ant-form-item-label{
    &>label{
      height: 40px;
      line-height: 40px;
    }
  }
  .ant-input-affix-wrapper{
    height: 40px;
    line-height: 40px;
  }
}
.searchFormItem.ant-form-item{
  flex-wrap: nowrap;
  margin-right: $Search__itemMargin_right;
  margin-bottom: $Search__itemMargin_bottom;
  &.noMarginRight{
    margin-right: 0;
  }
  &:nth-child(4n){
    margin-right: 0;
  }
  // 覆盖antd样式
  .ant-form-item-label{
    &>label{
      min-width: 80px;
      justify-content: right;
    }
  }
  .ant-form-item-control-input{
    height: 100%;
    &-content, .ant-input-group, .ant-picker, .ant-select-single, .ant-space, .ant-space-item{
      height: 100%;
      display: inline-flex;
      align-items: center;
      &-wrapper, input{
        height: 100%;
      }
      &-addon{
        border: 0;
      }
    }
    .ant-input-group{
      display: table;
    }
    .ant-space, .ant-space-item, .ant-input-number{
      width: 100%;
    }
    .ant-input-number, .ant-input-number-input-wrap{
      height: 100%;
    }
  }
  .ant-input-affix-wrapper{
    .ant-input{
      font-size: 16px;
      font-family: 'PingFangSC, PingFang SC';
      &::-webkit-input-placeholder {color:rgba(0,0,0,0.45)}
      &:-moz-placeholder {color:rgba(0,0,0,0.45)}
      &::-moz-placeholder {color:rgba(0,0,0,0.45)}
      &:-ms-input-placeholder {color:rgba(0,0,0,0.45)}
    }
  }
  @media screen and (max-width: 768px) {
    .ant-form-item-label{
      &>label{
        height: auto;
        line-height: normal;
        justify-content: start;
      }
    }
    .ant-input-affix-wrapper{
      .ant-input{
        font-size: 14px;
      }
    }
  }
  // 明文提示时
  &.formPlaintext{
    .ant-form-item-control-input{
      &-content, .ant-input-group, .ant-picker{
        flex-direction: column;
      }
    }
  }
  .ant-form-item-explain-error{
    font-size: 11px;
  }
  .ant-form-item-control{
    overflow: hidden;
  }
  // 输入框线条
  .ant-select-selector, .ant-input, .ant-input-affix-wrapper, .ant-picker{
    border: 1px solid $Theme__border_default !important;
    .ant-input{
      border: 0 !important;
    }
  }
  // 输入框线条占位
  .ant-select-selection-placeholder{
    color: $Theme__font_color4;
  }
  input, textarea{
    &::-webkit-input-placeholder{
      color: $Theme__font_color4;
    }
  }
}
.mobilePlatform__drawer{
  .ant-drawer-header{
    padding: 19.5px 24px;
  }
  .ant-drawer-body{
    padding: 10px 9px;
    position: relative;
  }
}
html {
  height: 100%;
}
body {
  font-size: 14px;
}

@keyframes running-line {
  to {
    stroke-dashoffset: -1000;
  }
}

@keyframes ant-line {
  to {
    stroke-dashoffset: -1000;
  }
}

.qcc-component-autocomplete-medium {
  width: 100% !important;
}
.qcc-input-affix-wrapper {
  border-radius: 6px !important;
}
.qcc-input::placeholder {
  color: rgba(191,191,191,0.85) !important;
}