.introContent{
  &_container{
    padding: 15px 16px;
    border-radius: 8px;
    background: linear-gradient(180deg, #D6DFFC 0%, #FFFFFF 29%, #FBFAFE 100%);
    border: 1px solid #E9E9E9;
    margin-bottom: 12px;
    margin-top: 12px;
    //background-image: url('../../../../assets/images/intro/backimg.png');
    //background-repeat: no-repeat;
    //background-size: 100% 100%;
    //width: 26%;
    // width: 458px;
    // flex: 1;
    //margin-right: 16px;
    //padding: 13px 6px 0px 6px;
    box-sizing: border-box;
    .companyList{
      display: flex;
      //justify-content: space-between;
      .item{
        width: 15%;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        span:nth-last-child(1){
          color: #3866E7;
          margin-top: 5px;
          font-size: 16px;
          .noStyle{
            font-size: 12px;
            color: rgba(0, 0, 0, 0.88);
            margin-left: 3px;
          }
        }
      }
    }
    .newCompany{
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 16px;
      .companyName{
        color: #000000;
        font-size: 20px;
        margin-right: 12px;
      }
    }
    //.companyName{
    //  position: relative;
    //  height: 59px;
    //  color: #fff;
    //  background-image: url("../../../../assets/icons/icon3.png");
    //  background-size: 100% 100%;
    //  background-repeat: no-repeat;
    //  .boxRadius{
    //    width: 46%;
    //    height: 20px;
    //    position: relative;
    //    background-color: #4a91f7;
    //    border-top-left-radius: 8px;
    //    &::after{
    //      content: '';
    //      position: absolute;
    //      top: 0;
    //      right: -29px;
    //      height: 100%;
    //      width: 30px;
    //      z-index: 2;
    //      background-color: #4a91f7;
    //      clip-path: path("M 0,0 C 10,0 10,5 24,20 L 0, 20 Z");
    //    }
    //  }
    //  &_title{
    //    font-size: 18px;
    //    font-weight: 500;
    //    // position: absolute;
    //    // top: 15px;
    //    // left: 3%;
    //    // z-index: 3;
    //    font-family: PingFangSC, PingFang SC;
    //    .industryName {
    //    font-family: PingFangSC, PingFang SC;
    //    color: #044DCC;
    //    font-style: normal;
    //    text-decoration-line: underline;
    //    font-size: 14px;
    //    // color: #044DCC;
    //    // text-decoration-line: underline;
    //    font-weight: 400;
    //    text-align: center;
    //    }
    //  }
    //  &_time{
    //    position: absolute;
    //    right:12px;
    //    top: 28px;
    //    font-family: PingFangSC, PingFang SC;
    //    font-size: 12px;
    //    color: rgba(255,255,255,0.85);
    //    font-weight: 400;
    //    // background-color: #4a91f7;
    //  }
    //}
  }
  &_innerContainer {
    background-image: url('../../../../assets/images/intro/innerback.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: relative;
    .innerContainerTop {
      position: absolute;
      top: 34px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 11px;
      color: #FFFFFF;
      padding: 0px 13px;
      line-height: 17px;
      left: 9px;
      display: inline-block;
      height: 18px;
      background: rgba(255,255,255,0.12);
      border-radius: 15px;
      border: 1px solid rgba(255,255,255,0.67);
    }
    .innerContainerBody {
      position: absolute;
      top: 68px;
      left: 22px;
      width: calc(100% - 44px);
      .innerContainerBodyName {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #044DCC;
        margin-bottom: 9px;
      }
      .introContent_row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .introContent_title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0,0,0,0.85);
          &::before{
            display: inline-block;
            content: '';
            width: 2px;
            height: 14px;
            background-color: rgba(4,77,204,0.95);
            margin-right: 5px;
          }
          .industryName {
            font-family: PingFangSC, PingFang SC;
            color: #044DCC;
            font-style: normal;
            text-decoration-line: underline;
            font-size: 14px;
            // color: #044DCC;
            // text-decoration-line: underline;
            font-weight: 400;
            text-align: center;
            margin-left: 10px; 
          }
        }
      }
      .introContent_items {
        margin-bottom: 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 14px;
        margin-left: 8px;
        color: rgba($color: #000000, $alpha: 0.65);
      }
    }
    .innerContainerModal {
      position: absolute;
      top: 18px;
      width: 28.3%;
      height: 36%;
      padding: 3.7% 3.6%;
      background: linear-gradient( 136deg, #487EFE 0%, #CDC1FD 100%);
      box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.07);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      right: 7.85%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(255,255,255,0.85);
      }
      span {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 800;
        font-size: 24px;
        color: #FFFFFF;
      }
    }
  }
  &_wrapper{
    position: relative;
    top: -9px;
    padding: 20px 14px 20px 16px; 
    background: linear-gradient( 180deg, #D6DFFC 0%, #FFFFFF 74%, #FBFAFE 100%);
    border-radius: 8px;
  }
  &_company{
    font-size: 16px;
    color: #044DCC;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    margin-bottom: 10px;
  }
  &_level{
    background-color: #417dff;
    box-shadow: 0 5px 6px 0 #6b9cfa52;
    border-radius: 50px;
    color: #fff;
    margin-left: 31px;
    font-size: 11px;
    // line-height: 11px;
    display: flex;
    align-items: center;
    padding: 5px 0px;
    // width: max-content;
    // margin-top: 4px;
    & > div{
      margin: 0 3px 0 8px;
      span{
        font-size: 16px;
        font-weight: 600;
        // margin-left: 5px;
        font-family: PingFangSC, PingFang SC;
        position: relative;
        top: -1px;
      }
    }
    img{
      width: 26px;
      height: 26px;
      border: 1px solid #407bfc;
      padding: 4px;
      border-radius: 100%;
      background: #2566ff;
    }
  }
  &_link, &_link:hover{
    color: #044DCC;
    text-decoration: underline;
    cursor: pointer;
  }
  &_items{
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin-left: 11px;
    margin-bottom: 9px;
    color: rgba($color: #000000, $alpha: 0.65);
    .industryName {
      color: rgba(0,0,0,0.85);
      width: 33%;
      font-family: PingFangSC, PingFang SC;
      color: #044DCC;
      font-style: normal;
      text-decoration-line: underline;
      font-size: 14px;
      // color: #044DCC;
      // text-decoration-line: underline;
      font-weight: 400;
      text-align: center;
      padding-right: 30px;
    }
    &_item{
      width: 33%;
    }
    &_value{
      align-content: flex-start;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size:14px;
      color: #044DCC;
    }
    &_secondValue {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0,0,0,0.65);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1600px) {
  /* 样式规则 */
 .introContent {
  &_innerContainer{
    .innerContainerBody {
      position: absolute;
      top: 68px;
      left: 22px;
      width: calc(100% - 44px);
      .innerContainerBodyName {
        width: 12vw;
        font-size: 0.95vw;
      }
      .introContent_row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5.25%;
        .introContent_title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 0.82vw;
          color: rgba(0,0,0,0.85);
          &::before{
            display: inline-block;
            content: '';
            width: 2px;
            height: 0.82vw;
            background-color: rgba(4,77,204,0.95);
            margin-right: 5px;
          }
          .industryName {
            font-family: PingFangSC, PingFang SC;
            color: #044DCC;
            font-style: normal;
            text-decoration-line: underline;
            font-size: 11px;
            // color: #044DCC;
            // text-decoration-line: underline;
            font-weight: 400;
            text-align: center;
            margin-left: 10px; 
          }
        }
      }
      .introContent_items {
        margin-bottom: 3.25%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 0.82vw;
        margin-left: 8px;
        color: rgba($color: #000000, $alpha: 0.65);
      }
    }
    .innerContainerModal {
      position: absolute;
      top: 15px;
      // width: 7.3vw;
      height: auto;
      padding: 3.7% 3.6%;
      background: linear-gradient( 136deg, #487EFE 0%, #CDC1FD 100%);
      box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.07);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      right: 7.85%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 0.82vw;
        color: rgba(255,255,255,0.85);
      }
      span {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 800;
        font-size: 1.3vw;
        color: #FFFFFF;
      }
    }
  }
  &_items{
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin-left: 11px;
    margin-bottom: 9px;
    color: rgba($color: #000000, $alpha: 0.65);
    .industryName {
      color: rgba(0,0,0,0.85);
      width: 33%;
      font-family: PingFangSC, PingFang SC;
      font-size: 0.82vw;
      color: #044DCC;
      font-style: normal;
      text-decoration-line: underline;
      font-weight: 400;
      // align-content:flex-start;
      // justify-items: flex-start;
      text-align: end;
      position: relative;
      bottom: 10px;
      padding-right: 24px;
    }
    &_item{
      width: 33%;
    }
    &_value{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size:0.85vw;
      // font-size: 13px;
      color: #044DCC;
      margin-top: 4px;
      & > div {
        font-size: 11px;
      }
      & > span {
        font-size: 10px;
      }
    }
    &_secondValue {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 0.82vw;
      color: rgba(0,0,0,0.65);
    }
  }
 }
}