.chartAnalysis {
  &_container {
    width: 24%;
    // flex: 1;
  }
  &_wrapper {
    position: relative;
    height: 100%;
    padding: 16px;
    border-radius: 8px;
    background: linear-gradient(180deg, #d6dffc 0%, #ffffff 29%, #fbfafe 100%);
    border: 1px solid #e9e9e9;
	.newBox{
		border:1px solid rgb(198, 212, 233);
		padding: 5px 10px;
		border-radius: 4px;
		background: #F5F7FB;
		.border{
			border-bottom: 1px solid rgb(198, 212, 233);
		}
		.desc{
			display: flex;
			justify-content: space-between;
			padding: 7px 0;
			padding-left: 10px;
			.values{
				background: #5D91EC;
				color: #ffffff;
				border-radius: 6px;
				font-size: 12px;
				padding: 2px 8px;
			}
		}
		.item{
			display: flex;
			justify-content: space-between;
			padding: 8px 0;
			.values{
				background: #5D91EC;
				color: #ffffff;
				border-radius: 6px;
				font-size: 12px;
				padding: 2px 8px;
			}
		}
	}
    :global {
      .ant-spin-nested-loading {
        width: 100%;
        height: 100%;
      }
      .ant-spin-container {
        height: 100%;
        width: 100%;
      }
    }
    .indicateBox {
      width: 100%;
      display: flex;
      justify-content: center;
      .indicate {
        width: 80%;
        display: flex;
        align-items: center;
        .strong {
          width: 15px;
          font-weight: 400;
          font-size: 14px;
          color: #08c3a5;
        }
        .img {
          flex: 1;
          img{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .weak {
          width: 20px;
          font-weight: 400;
          font-size: 14px;
          color: #e11616;
        }
      }
    }
  }
  &_title {
    color: rgba($color: #000000, $alpha: 0.85);
    font-size: 14px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    &_main {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      &_desc {
        margin-top: 1px;
        display: inline-block;
        //width: 40px;
        height: 15px;
        margin-left: 5px;
        padding: 0 4px;
        background: #5D91EC;
        border-radius: 7px;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &_link {
      display: flex;
      align-items: center;
      color: #0052d9;
      cursor: pointer;
    }
  }
  
}

.echarts_outer {
  border: none !important;
  width: 163px !important;
  padding: 12px !important;
  // height:80px !important;
  font-size: 12px !important;
  display: none;
  font-weight: 400 !important;
  border-radius: 4px !important;
  background: #ffffff !important;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12) !important;
}