.chartProcess{
  &_container{
    // width: 50%;
    flex-grow: 1;
    margin-right: 16px;
  }
  &_wrapper{
    position: relative;
    height: 100%;
    padding: 15px 9px;
    border-radius: 8px;
    background: linear-gradient( 180deg, #D6DFFC 0%, #FFFFFF 29%, #FBFAFE 100%);
    border: 1px solid #E9E9E9;
  }
  &_title{
    color: rgba($color: #000000, $alpha: 0.85);
    font-size: 14px;
    margin-bottom: 14px;
    padding: 0px 8px;
    display: flex;
    justify-content: space-between;
    &_main{
      font-weight: 500;
      font-size: 14px;
      color: rgba(0,0,0,0.85);
    }
    &_link{
      color: #044DCC;
      cursor: pointer;
    }
  }
  &_flowCpn{
    width: 100%;
    height: calc(100% - 33px);
    :global {
      .ant-spin-nested-loading {
        width: 100%;
        height: 100%;
      }
      .ant-spin-container{
        height: 100%;
        width: 100%;
      }
    }
  }
}
.modalContent{
  padding: 0 24px;
}