.container{
	background-color: #fff;
	padding: 16px 20px 10px;
	.bankBox{
		border: 1px solid #e9e9e9;
		padding-bottom: 10px;
	}
	.bankTitle{
		background-color: #dc042c;
		color: #fff;
		padding: 5px 10px;
		font-weight: bold;
	}
	.download{
		display: flex;
		justify-content: flex-end;
		margin-bottom: 15px;
	}
	.downloadBtn{
		background: #dc042c;
	}
	.downloadBtn:hover{
		background: #dc042c!important;
	}
	.table{
		display: flex;
		flex-direction: column;
		width: 100%;
		border-radius: 2px;
		overflow: hidden;
		
		.tableTitle{
			background-color: #dc042c;
			color: #fff;
			font-weight: bold;
		}
		.tr:nth-child(1){
			border: 1px solid #dc042c;
		}
		.noTr {
			display: flex;
			border: 1px solid #e9e9e9;
			border-top: none;
			.td{
				width: 25%;
				padding: 5px 10px;
			}
		}
		.tr{
			display: flex;
			border: 1px solid #e9e9e9;
			border-top: none;
			.td{
				width: 25%;
				padding: 5px 10px;
			}
		}
	}
	.detailContent{
		width: 100%;
		margin-top: 10px;
		border-radius: 2px;
		overflow: hidden;
		border: 1px solid #e9e9e9;
		border-top: none;
		.assessment{
			float: left;
			width: calc(50% - 8px);
			margin-right: 8px;
			border: 1px solid #e9e9e9;
			border-left: none;
			// border-bottom: none;
			.title{
				background-color: #dc042c;
				font-weight: bold;
				color: #fff;
				padding: 5px 10px;
				span{
					display: inline-block;
					width: 50%;
				}
			}
			.content{
				display: flex;
				justify-content: space-between;
				.left{
					width: 100%;
					border-right: 1px solid #e9e9e9;
					
					.line{
						border-bottom: 1px solid #e9e9e9;
						padding: 5px 10px;
						font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: center;
						span{
              width: 50%;
							// display: inline-block;
							// width: 50%;
							// text-align: left;
						}
					}
				}
				.right{
					width: 50%;
					.rightTitle{
						text-align: center;
						font-weight: bold;
						padding: 5px 10px;
					}
					.line{
						border-bottom: 1px solid #e9e9e9;
						padding: 5px 10px;
						span{
							display: inline-block;
							width: 50%;
							text-align: right;
						}
					}
				}
			}
		}
		.indicators{
			.title{
				background-color: #dc042c;
				font-weight: bold;
				color: #fff;
				padding: 5px 10px;
			}
		}
	}
	.desc{
		color: #8e8e8e;
		font-size: 12px;
	}
	.tips{
		border: 1px solid #e9e9e9;
		border-top: none;
		padding: 5px 10px;
		font-size: 12px;
		color: #8e8e8e;
	}
	.bqTips{
		// padding: 5px 10px;
		margin-top: 12px;
		font-size: 12px;
	}
}