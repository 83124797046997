.tabsDetail{
  &_container{
    
  }
  &_wrapper{
    display: flex;
  }
  &_tabs{
    margin-right: 20px;
    max-width: 230px;
    cursor: pointer;
    &_activeitem {
      border: 1px solid #044DCC !important;
    }
    &_item{
      padding: 5px 12px;
      border-radius: 4px;
      border: 1px solid transparent;
      position: relative;
      display: flex;
      align-items: center;
      background: #F5F7FB;
      &_iconRight{
        position: absolute;
        top: 0;
        right: 0;
        width: 17px;
      }
      &_icon{
        width: 38px;
        margin-right: 14px;
      }
      &_titleContent{
        font-size: 14px;
        color: #333333;
        margin-bottom: 3px;
        width:103px;
        flex: 1;
        &_active{
          color: #044DCC;
          font-weight: 500;
        }
        overflow: hidden;
        &_title{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &_title_2{
          &_inner {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            .tabTitle {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: calc(100% - 18px);
            }
            & > .titleContent_rating {
              padding-left: 6px;
              flex-shrink: 0; 
              align-self: center;
            }
          }
          
          // width: calc(100% - 38px);
          
        }
        .titleContent{
			    width: 104px;
          &_ratingContainer{
			      width: 99px;
            height: 30px;
            position: relative;
          }
          &_lineContainer{
            height: 5px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            div{
              width: 100%;
              border: 0.5px dashed #979797;
              position: absolute;
              top: 2px;
            }
            span{
              width: 1px;
              height: 100%;
              display: inline-block;
              background-color: #979797;
              position: absolute;
              top: 0;
            }
          }
          &_ratingDetail{
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            img{
              width: 10px;
            }
            span{
              font-weight: 500;
              font-size: 10px;
            }
          }
          &_rating{
            font-size: 11px;
            font-weight: 500;
            text-align: center;
            &.greenC{
              color: #52C41A;
			  margin-left: -4px;
            }
            &.redC{
              color: #FF4D4F;
            }
            &.greyC{
              color: grey;
            }
          }
        }
      }
      &_detail{
        margin-left: 8px;
        width: 16px;
      }
    }
  }
  &_content{
    overflow: auto;
    padding-right: 8px;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 6px;
      background : rgba(0,0,0,0.15);;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 6px;
      background : #FEFEFF;
    }
    &_title{
      position: relative;
      height: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: rgba(0,0,0,0.85);

      // &::before{
      //   content: '';
      //   display: inline-block;
      //   width: 2px;
      //   height: 14px;
      //   background: #044DCC;
      //   opacity: 0.6596;
      //   position: absolute;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   left: 0;
      // }
    }
    &_chooseTitle {
      // width: 88px;
      display: inline-block;
      height: 28px;
      background: #3866E7;
      border-radius: 4px 0px 4px 0px;
      border: 1px solid #044DCC;
      text-align: center;
      line-height: 14px;
      box-sizing: border-box;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500 !important;
      font-size: 14px;
      color: #FFFFFF !important;
      padding: 6px 16px;
    }
    &_detail{
      // margin: 5px 0 0 7px;
      line-height: 22px;
    }
    li{
      list-style: none;
      font-size: 14px;
      color: rgba(0,0,0,0.65);
      &.active{
        background: #EDF1FD;
        border-radius: 4px;
        border: 1px solid #044DCC;
        .tabsDetail_content{
          &_title{
            font-weight: 500;
            color: #000000;
            // &::before{
            //   opacity: 1;
            //   width: 0;
            //   height: 0;
            //   background-color: transparent;
            //   border-width: 0 10px 10px 0;
            //   border-style: solid;
            //   border-color: transparent #044DCC transparent transparent;
            //   transform: rotateZ(45deg);
            //   top: 5px;
            //   left: -5px;
            // }
          }
          &_detail{
            color: rgba(0,0,0,0.85);
          }
        }
      }
      &:last-child {
        // margin-bottom: 1px;
      }
    }
    
  }
  &_title{
    color: rgba($color: #000000, $alpha: 0.85);
    font-size: 14px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    &_link{
      color: #044DCC;
    }
  }
}