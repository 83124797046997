.modalContainer{
  &.ant-modal{
    .ant-modal-content{
      padding: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .modalContainer{
    max-width: calc(100% - 30px) !important;
  }
}