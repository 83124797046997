.nodeOuterStyle {
    width: 114px;
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 6px 30px 5px rgba(0,0,0,0.05), 0px 8px 10px -5px rgba(0,0,0,0.08);
    border-radius: 4px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
  }
  .nodeOuter2Style {
    width: 147px;
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 6px 30px 5px rgba(0,0,0,0.05), 0px 8px 10px -5px rgba(0,0,0,0.08);
    border-radius: 4px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
  }
  .nodeOuterName {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
  }
  .nodeOuterFNumber {
    width: 30px;
    height: 15px;
    background: #5D91EC;
    border-radius: 7px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media screen and (min-width: 768px) and (max-width: 1600px){
      font-size: 10px;
    }
  }
  .nodeOuterZNumber {
    width: 30px;
    height: 15px;
    background: #5D91EC;
    border-radius: 7px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media screen and (min-width: 768px) and (max-width: 1600px){
      font-size: 10px;
    }
  }
  .nodeOuterImg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: -1px;
    right: -1px;
  }
  
  .nodeOuterImgForSafari {
    width: 13px;
    height: 13px;
    flex-shrink: 0;

    align-self: start;
  }

  .spinname {
    width: 100%;
    height: 100%;
  }

  .container {
    & > div {
      position: static !important;
    }
   & > svg {
      position: static !important;
      // top: unset !important;
      // left: unset !important;
      // right: unset !important;
      // bottom: unset !important;
    }
  }