.echartsBox {
  // margin-top: 20px;
  // background: #f7f8fa;
  // padding: 24px;
  border-radius: 8px;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #1D2129;
  }
  .echartsList {
    width: 100%;
    // margin-top: 10px;
    // padding-top: 20px;
    border-radius: 8px;
    // background: #ffffff;
    //display: flex;
    //overflow-x: auto;
    //flex-wrap: wrap;
    &.empty{
      display: block;
      padding-bottom: 20px;
    }
    .echartsItem {
      width: 100%;
      //min-width: 230px;
      // border-bottom: 1px solid #666;
      // padding: 10px 20px;
      padding-top: 10px;
      margin-right: 30px;
      .echartsTitle {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        color: #1d2129;
      }
    }
    .echartsItem:nth-child(4n) {
      margin-right: 0px;
    }
  }
}