.echartsList {
  width: 100%;
  margin-top: 10px;
  padding-top: 20px;
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;

  .echartsItem {
    width: 25%;
    border-bottom: 1px solid #666;
    padding: 10px 20px;
    min-width: 230px;
    .echartsTitle {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
    }
  }
}
