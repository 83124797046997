/*
*公共样式文案
*/
@charset "utf-8";

$mainColor: #5B6990;// 主色
$btnRadius: 4px;
$greyBorder: 1px solid #f4f4f4;
/**
 * @description: 布局样式
 */
$System_loyout_header_height: 74px;  //header高度
$System_loyout_header_bg: #fff;  //header背景色
/**
 * @description: 字体样式
 */
$Theme__font: #5B6990; // 默认字体颜色
$Theme__font_size_large: 16px; // 默认字体大小-大
$Theme__font_size_default: 14px; // 默认字体大小-中
$Theme__font_size_small: 12px; // 默认字体大小-小
$Theme__pgBgColor: #F8F8FA; //页面背景色
$Theme__captionFont: #222D4B; // 默认标题色
$Theme__table_title: #334168; // 默认表单标题色
$Theme__sub_title: #B4BCD1; // 默认副标题
$Theme__side_default: #B4BCD1; // 默认side标题色
$Theme__font_color1: #0052D9; // 默认字体色1
$Theme__font_color2: #126BF1; // 默认字体色2
$Theme__font_color3: #FF6E4A; // 默认字体色3
$Theme__font_color4: #D6D7E2; // 默认字体色4
$Theme__font_color5: #1677ff; // 默认字体色5
/**
 * @description: 按钮样式
 */
$Theme__button_bgColor: #0052D9; // 默认按钮背景色
$Theme__button_font1: #FFFFFF; // 默认按钮字体色1
$Theme__button_font2: #5B6CFF; // 默认按钮字体色2
$Theme__button_border1: #5B6CFF; // 默认按钮边框色1
/**
 * @description: 线条样式
 */
$Theme__border_default: #ECEEF6; // 默认线条样式
/**
 * @description: 查询条件配置
 */
 $Search__itemMargin_bottom: 16px; // 查询条件下边距
 $Search__itemMargin_right: 15px; // 查询条件右边距
/**
 * @description: 背景色
 */
$Theme__bgColor_table: #F3F4FF; // table标题背景色
$Theme__bgColor_side: #33426A; // side背景色
$Theme__bgColor_subSide: #222D4B; // side背景色
$Theme__bgColor_bg1: #F2F5FE; // 默认间隔背景色1
$Theme__bgColor_bg2: rgba(#CA63FF,0.4); // 默认间隔背景色2
$Theme__bgColor_bg3: rgba(#5B6CFF,0.62); // 默认间隔背景色3
/**
 * @description: 表单样式配置
 */
 $Form__default_font: rgba(0, 0, 0, 0.85); // 表单中默认色
 $Form__echo_font: #A9B0B7; // 表单中置灰
 $Form__edit_font: #f00; // 表单中更改色
 
%disabledSelect {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

%overWriteAntd {// antd样式覆盖
  .ant-input-number {
    width: 110px;
    &:hover,
    &:focus {
      border-color: $mainColor;
    }
  }
  .ant-input:hover {
    border-color: $mainColor;
  }
  .ant-input:focus {
    border-color: $mainColor;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: $mainColor;
  }
  .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: $mainColor;
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: $mainColor;
  }
  .ant-select-selection:hover {
    border-color: $mainColor;
  }
  .ant-select-focused {
    .ant-select-selection {
      border-color: $mainColor;
    }
  }
  .ant-checkbox-checked {
    &:after {
      border: 1px solid $mainColor;
    }
    .ant-checkbox-inner {
      background-color: $mainColor;
      border-color: $mainColor;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid $mainColor !important;
  }
  .ant-btn-primary {
    color: #fff !important;
    background-color: $mainColor !important;
    border-color: $mainColor !important;
  }
  .ant-btn {
    color: $mainColor;
    background-color: #f9fdfb;
    &:hover {
      //color: $mainColor;
      border-color: $mainColor;
    }
  }
  .ant-modal-footer {
    .ant-btn {
      &:hover {
        color: $mainColor;
      }
    }
  }
  .ant-tabs-nav {
    font-size: 16px;
  }
  .ant-tabs-nav-wrap {
    padding: 0 30px;
  }
  .ant-tabs-tab-active {
    color: $mainColor;
  }
  .ant-tabs-ink-bar {
    height: 3px;
    background-color: $mainColor;
  }
  .ant-tabs-tab {
    &:hover {
      color: $mainColor;
    }
  }
  .ant-radio-group {
    text-align: center;
  }
  .ant-radio-button-wrapper {
    width: 100px;
    height: 46px;
    line-height: 46px;
  }
  .ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper-checked {
    background-color: $mainColor;
    color: #fff;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: $mainColor;
    background-color: $mainColor;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    border-color: $mainColor;
    background-color: $mainColor;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    border-color: $mainColor;
    background-color: $mainColor !important;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border-color: $mainColor;
    background-color: $mainColor;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    opacity: 1;
  }
  .ant-table-thead > tr > th {
    color: $Theme__captionFont;
    background-color: $Theme__bgColor_table;
    font-weight: bold;
  }
  .ant-table-tbody > tr > td {
    word-break: break-all;
    color: $mainColor;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 12px 4px;
  }
  .ant-table-fixed {
    margin-bottom: 10px;
  }
  .ant-table-body {
    overflow: auto;
  }
  .ant-table-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #eee;
  }

  /* 滚动槽 */
  .ant-table-body::-webkit-scrollbar-track {
    border-radius: 3px;
    height: 10px;
  }

  /* 滚动条滑块 */
  .ant-table-body::-webkit-scrollbar-thumb {
    height: 10px;
    border-radius: 10px;
    background: #999;
  }
  .ant-table-content {
    overflow: auto;
  }
  .ant-tag {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
  .ant-tag-checkable-checked {
    color: $mainColor;
    // background-color: $mainColor;
  }
  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: $mainColor;
  }

  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
    background-color: $mainColor;
  }
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: $mainColor;
    border-color: $mainColor;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $mainColor;
  }
  .ant-select-tree-checkbox-checked::after {
    border: $mainColor;
  }
  .ant-menu-horizontal {
    background: #002f5b;
    border: none;
    color: #fff;
  }
  .ant-menu-horizontal > .ant-menu-item-selected > a {
    color: #8baad6;
    height: 58px;
    line-height: 58px;
    &:hover {
      color: #8baad6;
    }
  }
  .ant-menu-submenu-popup {
    background: #526580;
  }
  .ant-menu-vertical.ant-menu-sub,
  .ant-menu-vertical-left.ant-menu-sub,
  .ant-menu-vertical-right.ant-menu-sub {
    min-width: 80px;
  }
  .ant-menu-submenu > .ant-menu {
    background-color: #002f5b;
    border-radius: 0;
  }
  .ant-menu-item > a {
    color: #fff;
    text-align: center;
    &:hover {
      color: #fff;
    }
  }
  .ant-menu-item:hover {
    background-color: $mainColor;
  }
  .ant-menu-item-selected > a,
  .ant-menu-item-selected > a:hover {
    color: #fff;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #002f5b;
    a {
      color: $mainColor;
    }
  }
  .ant-menu-item > a:hover {
    color: #fff;
  }
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    background: none;
  }
  li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
  li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
  li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
    color: rgba(0, 0, 0, 0.65);
  }
}

%btnStyle {
  @extend %disabledSelect;
  border-radius: 4px;
  box-shadow: 2px 1px 2px 0px rgba(0, 0, 0, 0.13);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}

:export {
  searchRight: $Search__itemMargin_right;
  mainColor: $mainColor;
}
