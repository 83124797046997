/**
 * @description: 操作按钮
 */
 @mixin oprationBtn( $bgColor: #fff, $fontColor: $Theme__button_font1, $borColor: $Theme__button_border1) {
  background-color: $bgColor;
  color: $fontColor;
  border: 1px solid $borColor;
  border-radius: 2px;
  text-align: center;
  padding: 5px 16px;
  margin-right: 10px;
  cursor: pointer;
}
.modal{
  &_wrapper{
    
  }
  &_content{
    color: rgba(0,0,0,0.85);
    font-size: 16px;
    margin-top: 20px;
    background: linear-gradient( 180deg, #F1F5FF 0%, #FFFFFF 24%, #FFFFFF 100%);
    // text-align: center;
    border-radius: 8px;
  }
}
.footer_wrapper{
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 10px;
  margin-right: 14px;
}
.footer_confirm{
  @include oprationBtn(#0052D9,#FFFFFF,#0052D9);
}
.footer_cancle{
  @include oprationBtn(#F3F3F3,#171717,#F3F3F3);
  margin-right: 20px;
}
.modalContainer{
  &__title{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,0.85);
    padding-top: 25px;
    margin-bottom: 32px;
  }
  &__customer{
    font-weight: 400;
    font-size: 12px;
    color: rgba(0,0,0,0.45);
    text-align: center;
    border-bottom: 1px solid #00000008;
    &_scan{
      margin: 14px 0 10px;
    }
    &_img{
      width: 110px;
    }
    &_mobile{
      margin: 4px 0 14px;
    }
  }
}
@media screen and (max-width: 767px) {
  .modalContainer{
    &__customer{
      border-width: 0;
    }
  }
  .footer{
    &_wrapper{
      justify-content: space-between;
      padding: 10px 15px 24px;
      margin-right: 0;
    }
    &_cancle{
      @include oprationBtn(#fff,#0052D9,#0052D9);
      border-radius: 8px;
      padding: 10px 0;
      width: calc(50% - 4px);
    }
    &_confirm{
      @include oprationBtn(#0052D9,#FFFFFF,#0052D9);
      border-radius: 8px;
      padding: 10px 0;
      width: calc(50% - 4px);
      margin-right: 0;
    }
  }
}