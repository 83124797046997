.echartsBox {
  // margin-top: 20px;
  // background: #f7f8fa;
  // padding: 24px;
  border-radius: 8px;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #1D2129;
  }
  .echartsList {
    width: 100%;
    // margin-top: 10px;
    // padding-top: 20px;
    border-radius: 8px;
    // background: #ffffff;
    //display: flex;
    //overflow-x: auto;
    //flex-wrap: wrap;
    &.empty{
      display: block;
      padding-bottom: 20px;
    }
    .echartsItem {
      width: 100%;
      //min-width: 230px;
      // border-bottom: 1px solid #666;
      // padding: 10px 20px;
      //margin-right: 30px;
      .echartsTitle {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        color: #1d2129;
      }
    }
    .echartsItem:nth-child(4n) {
      margin-right: 0px;
    }
  }
}
.IndicatorsAndComparisonTableList_table {
    margin-top: 10px;
  position: relative;
  .echartsSelect{
    position: absolute;
    top: -34px;
    left: 70px;
  }
  .backStyle{
    position: absolute;
    top: -26px;
    left: 310px;
    font-size: 12px;
    color: #3866E7;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    align-items: center;
  }
    :global {
      .ant-table-tbody > tr >td {
        padding: 10px 24px !important;
    }
    .ant-table-thead > tr >th{
        padding: 10px 24px !important;
    }
    .ant-table-tbody > tr >td:not(:last-child) {
        border-right: 0px !important;
    }
    .ant-table-thead > tr >th:not(:last-child) {
        border-right: 0px !important;
    }
    .ant-table.ant-table-bordered >.ant-table-container {
      border-top: none;
      border-left: none;
      border-right: none;
    }
    .ant-table-cell {
      border-right: none !important;
    }
    .ant-table-thead {
      .ant-table-cell {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #586068;
      }
    }
    .ant-table-tbody .ant-table-row {
      td{
        font-weight: 400;
        font-size: 14px;
        color: rgba(0,0,0,0.85);
        font-family: PingFangSC, PingFang SC;
        &:first-child{
          color: #586068 !important;
        }
      }
     
    }
    .ant-table-row-level-1 .ant-table-row-expand-icon-spaced {
      display: none;
    }
    }
}