.financialAnalusis{
  &_container{
    .even {
      background-color: #ffffff;
    }
    .odd {
      background-color: #f7fcff;
    }
    :global {
      .ant-radio-button-wrapper {
        background-color: transparent !important;
        font-family: PingFangSC, PingFang SC !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        color: rgba(0,0,0,0.6) !important;
        border: none !important;
        &::before {
          background-color: transparent !important;
        }
        &:hover {
          background-color: transparent !important;
          font-family: PingFangSC, PingFang SC !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          color: rgba(0,0,0,0.6) !important;
        }
      }
      .ant-radio-button-wrapper-checked {
        background-color: #FFFFFF !important;
        font-family: PingFangSC, PingFang SC !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: rgba(0,0,0,0.9) !important;
        border:none;
        &::before {
          background-color: transparent !important;
        }
        &:hover {
          background-color: #FFFFFF !important;
          font-family: PingFangSC, PingFang SC !important;
          font-weight: 500 !important;
          font-size: 14px !important;
          color: rgba(0,0,0,0.9) !important;
        }
      }
    }
  }
  &_table{
    margin-top: 10px;
    :global {
      .ant-table-tbody > tr >td {
        padding: 10px 24px !important;
    }
    .ant-table-thead > tr >th{
        padding: 10px 24px !important;
    }
    .ant-table-tbody > tr >td:not(:last-child) {
        border-right: 0px !important;
    }
    .ant-table-thead > tr >th:not(:last-child) {
        border-right: 0px !important;
    }
    .ant-table.ant-table-bordered >.ant-table-container {
      border-top: none;
      border-left: none;
      border-right: none;
    }
    .ant-table-cell {
      border-right: none !important;
    }
    .ant-table-thead {
      .ant-table-cell {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #586068;
      }
    }
    .ant-table-tbody .ant-table-row {
      td{
        font-weight: 400;
        font-size: 14px;
        color: rgba(0,0,0,0.85);
        font-family: PingFangSC, PingFang SC;
        &:first-child{
          color: #586068 !important;
        }
      }
     
    }
    .ant-table-row-level-1 .ant-table-row-expand-icon-spaced {
      display: none;
    }
    }
  }
}
