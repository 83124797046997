@import '@/styles/global.scss';
.custom-modal-body {
  p {
    & + p {
      margin-top: .5rem;
    }
  }
}
.modalContainer{
  &__wechatQRCode{
    &_wrapper{
      // display: flex;
      // flex-direction:row-reverse;
    }
    &_content{
      text-align: center;
    }
    &_scan{
      margin: 14px 0 10px;
      color: rgba(0,0,0,0.45);
    }
    &_img{
      width: 110px;
    }
  }
}
@media screen and (max-width: 767px) {
  .modalContainer{
    max-width: calc(100% - 30px) !important;
    &__header{
      border-radius: 8px 8px 0 0 !important;
    }
    &__confirmBtn{
      @include oprationBtn(#fff,#0052D9,#0052D9);
      border-radius: 8px;
      padding: 10px 0;
      margin-right: 0;
      width: 100%;
    }
    &__wechatQRCode{
      &_content{
        font-size: 12px;
      }
    }
  }
}