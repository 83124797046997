.newsList{
  &_container{
    
  }
  &_wrapper{

  }
  &_title{
    width: 56%;
    font-weight: 500;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    margin-bottom: 12px;
  }
  &_secondtitle {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    flex-grow: 1;
    margin-left: 2.3%;
    margin-right: 2.6%;
    margin-bottom: 12px;
  }
  &_content{
    display: flex;
    .mainContent{
      display: flex;
      justify-content: center;
      align-items: center;
      // flex-grow: 1;
      width: 56%;
      height: 171px;
      // margin-right: 12px;
      &_num{
        font-size: 14px;
        color: rgba(0,0,0,0.45);
        margin-bottom: 20px;
        text-align: center;
        span{
          font-size: 36px;
          margin-right: 6px;
          color: #333333;
        }
        div {
          font-size: 16px;
          color: rgba(0,0,0,0.65);
        }
      }
      &_title{
        
      }
      .mainContent_left {
        width: 28%;
        height: 100%;
        margin-right: 12px;
        background-image: url('../../../../assets/images/queryView/news_detail_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .newListContentOuter {
      padding: 17px 5px 17px 14px;
      border-radius: 4px;
      border: 1px solid #CCDFFF;
      // flex-grow: 1;
      width: 71%;
      height: 100%;
      overflow: hidden;
      .newsListContent{
        max-height: 136px;
        overflow: auto;
        padding-right: 9px;
        &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width : 6px; /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 6px;
          background : rgba(0,0,0,0.15);;
        }
        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          border-radius: 6px;
          background : #FEFEFF;
        }
        &_item{
          list-style: none;
          font-size: 16px;
          color: rgba(0,0,0,0.65);
          display: flex;
          justify-content: space-between;
          margin-bottom: 18px;
          cursor: pointer;
          &:last-child{
            margin-bottom: 0;
          }
          &_title{
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &_time{
            display: inline-flex;
            align-items: center;
            margin-left: 20px;
            color: rgba(0,0,0,0.45);
            font-size: 14px;
            img{
              width: 8px;
              height: 12px;
              margin-left: 12px;
            }
          }
        }
      }
    }
   
  }
  &_rightCharts {
    // width: 628px;
    // width: 37%;
    flex-grow: 1;
    margin-left: 2.1%;
    margin-right: 2.6%;
    position: relative;
  }
  &_rightChartsTitle {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #B0B5B9;
    margin-bottom: 8px;
    margin-left: 40px;
    position: absolute;
  }
}
