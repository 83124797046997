.containerBox{
  &_container{
   margin-bottom: 12px;
  }
  &_wrapper{
    position: relative;
    height: 100%;
    padding: 12px 16px 15px 16px;
    border-radius: 2px;
    background: linear-gradient( 180deg, #E5EEFF 0%, #FFFFFF 29%, #FBFAFE 100%);
    border: 1px solid #E9E9E9;
  }
  &_title{
    color: rgba($color: #000000, $alpha: 0.85);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    &_link{
      color: #044DCC;
    }
  }
}